import React, { useState, useEffect, useRef } from "react"
import Layout from "../components/layout"
import NewUIListingCard from "../components/Card/newuilistingcard"
import SEOHeader from "../components/seo-header"
import { graphql, Link, navigate } from "gatsby"
import { MeetingLocalityContent } from "../components/pagecontent/meetingcontent"
const MeetingSpacesLocality = props => {
  const { city } = props.pageContext
  const { cityslug } = props.pageContext
  const { locality } = props.pageContext
  const { slug } = props.pageContext
  const [spaceFilter, setspaceFilter] = useState("All")
  const [spaceSize, setspaceSize] = useState([])
  const [priceRange, setpriceRange] = useState(50)

  const [filterStyle, setfilterStyle] = useState("Popular")
  const [ProjectFilter, setProjectFilter] = useState("false")
  const [CoffeeTeaFilter, setCoffeeTeaFilter] = useState("false")
  const [LessFiveFilter, setLessFiveFilter] = useState(false)
  const [SixtoFifteenFilter, setSixtoFifteenFilter] = useState(false)
  const [MorethanFiftyFilter, setMorethanFiftyFilter] = useState(false)
  const [parkingCheck, setparkingCheck] = useState(false)
  const [projectorCheck, setprojectorCheck] = useState(false)
  const [whiteboardCheck, setwhiteboardCheck] = useState(false)
  const [cafeteriaCheck, setcafeteriaCheck] = useState(false)
  const [powerBackup, setpowerBackup] = useState(false)
  const [WiFiBackupCheck, setWiFiBackupCheck] = useState(false)
  const [PowerBackupCheck, setPowerBackupCheck] = useState(false)
  const [MetroNearbyCheck, setMetroNearbyCheck] = useState(false)
  const [CoffeeTea, setCoffeeTea] = useState(false)
  const [mobileFilterSecondary, setmobileFilterSecondary] = useState(false)
  const [mobileFilter, setmobileFilter] = useState(false)
  const [OnetoOneMeetings, setOnetoOneMeetings] = useState("")
  const [TeamMeetings, setTeamMeetings] = useState("")
  const [BoardMeetingsPresentations, setBoardMeetingsPresentations] = useState(
    ""
  )
  const [Interviews, setInterviews] = useState("")
  const [WorkshopsEvents, setWorkshopsEvents] = useState("")
  const [Training, setTraining] = useState("")
  const [operationTime, setoperationTime] = useState("")
  const [operationFilter, setoperationFilter] = useState(" ")
  const [sundayOperation, setsundayOperation] = useState(false)
  const [saturdayOperation, setsaturdayOperation] = useState(false)
  const [budgetFilter, setbudgetFilter] = useState(false)
  const [businessFilter, setbusinessFilter] = useState(false)
  const [premiumFilter, setpremiumFilter] = useState(false)
  const [filterType, setFilterType] = useState("")
  const [miniValue, setminiValue] = useState(-1)

  const [maxiValue, setmaxiValue] = useState(18000)

  const [optionValue, setoptionValue] = useState("")
  const filter = {
    keyword: city,
  }

  const operationTimeChange = e => {
    setoperationTime(e.target.value)
    if (e.target.value == "Open till 8 pm") {
      setoperationFilter("Till 8PM")
    } else if (e.target.value == "Open till 10 pm") {
      setoperationFilter("Till 10PM")
    } else if (e.target.value == "24 x 7") {
      setoperationFilter("Twenty Four Hours")
    }
  }

  const budgetFilterCheck = () => {
    setbudgetFilter(!budgetFilter)
    setbusinessFilter(false)
    setpremiumFilter(false)
  }
  const businessFilterCheck = () => {
    setbusinessFilter(!businessFilter)
    setbudgetFilter(false)
    setpremiumFilter(false)
  }
  const premiumFilterCheck = () => {
    setpremiumFilter(!premiumFilter)
    setbusinessFilter(false)
    setbudgetFilter(false)
  }
  const parkingCheckOnChange = () => {
    setparkingCheck(!parkingCheck)
  }
  const projectorCheckOnChange = () => {
    setprojectorCheck(!projectorCheck)
  }
  const whiteboardCheckOnChange = () => {
    setwhiteboardCheck(!whiteboardCheck)
  }
  const cafeteriaCheckOnChange = () => {
    setcafeteriaCheck(!cafeteriaCheck)
  }
  const powerBackupOnChange = () => {
    setpowerBackup(!powerBackup)
  }
  const WiFiBackupCheckOnChange = () => {
    setWiFiBackupCheck(!WiFiBackupCheck)
  }
  const PowerBackupCheckOnChange = () => {
    setPowerBackupCheck(!PowerBackupCheck)
  }
  const MetroNearbyCheckOnChange = () => {
    setMetroNearbyCheck(!MetroNearbyCheck)
  }
  const CoffeeTeaonChange = () => {
    setCoffeeTea(!CoffeeTea)
  }

  const OnetoOneMeetingsChange = () => {
    setOnetoOneMeetings(!OnetoOneMeetings)
  }
  const TeamMeetingsChange = () => {
    setTeamMeetings(!TeamMeetings)
  }
  const BoardMeetingsPresentationsChange = () => {
    setBoardMeetingsPresentations(!BoardMeetingsPresentations)
  }
  const InterviewsChange = () => {
    setInterviews(!Interviews)
  }
  const TrainingChange = () => {
    setTraining(!Training)
  }
  const WorkshopsEventsChange = () => {
    setWorkshopsEvents(!WorkshopsEvents)
  }
  const saturdayOperationChange = () => {
    setsaturdayOperation(!saturdayOperation)
  }
  const sundayOperationChange = () => {
    setsundayOperation(!sundayOperation)
  }
  function CoffeeTeaCheck() {
    setCoffeeTeaFilter(!CoffeeTeaFilter)
  }
  function ProjectCheck() {
    setProjectFilter(!ProjectFilter)
  }
  function LessFiveCheck() {
    setLessFiveFilter(!LessFiveFilter)
  }
  function SixtoFifteenCheck() {
    setSixtoFifteenFilter(!SixtoFifteenFilter)
  }
  function MorethanFiftyCheck() {
    setMorethanFiftyFilter(!MorethanFiftyFilter)
  }
  function priceChange(event) {
    setpriceRange(event.target.value)
  }
  var projectquery,
    coffeeteaquery,
    fiveseatquery,
    sixtofifteenquery,
    morethanfiftyquery,
    fivefiftyquery,
    tvquery = " "

  if (ProjectFilter === "true") {
    projectquery = "Projector"
    tvquery = "Television"
  } else {
    projectquery = " "
    tvquery = " "
  }
  if (CoffeeTeaFilter === true) {
    coffeeteaquery = "Coffee/Tea"
  } else {
    coffeeteaquery = " "
  }
  if (LessFiveFilter) {
    fiveseatquery = "Less Than Five"
  } else {
    fiveseatquery = " "
  }
  if (SixtoFifteenFilter) {
    sixtofifteenquery = "Five to Fifteen"
  } else {
    sixtofifteenquery = " "
  }
  if (MorethanFiftyFilter) {
    morethanfiftyquery = "More than Fifteen"
  } else {
    morethanfiftyquery = " "
  }
  if (LessFiveFilter && SixtoFifteenFilter) {
    fivefiftyquery = "moption a"
  }
  if (LessFiveFilter && MorethanFiftyFilter) {
    fivefiftyquery = "moption b"
  }
  if (SixtoFifteenFilter && MorethanFiftyFilter) {
    fivefiftyquery = "moption c"
  }
  if (
    LessFiveFilter === "true" &&
    SixtoFifteenFilter === "true" &&
    MorethanFiftyFilter === "true"
  ) {
    fivefiftyquery = "moption d"
  }

  const OfficeFilter = () => {
    useEffect(() => {
      setspaceSize(document.querySelectorAll(".listingCardPaddingNew").length)
    })

    return (
      <div className="officefiltercontainer">
        <ul className="OfficeFilter scrollmenu">
          <li>
            <a className={"CheckBox " + LessFiveFilter} onClick={LessFiveCheck}>
              &lt; 5 Seats{" "}
              <i className="fa fa-times-circle" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a
              className={"CheckBox " + SixtoFifteenFilter}
              onClick={SixtoFifteenCheck}
            >
              6 to 15 Seats{" "}
              <i className="fa fa-times-circle" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a
              className={"CheckBox " + MorethanFiftyFilter}
              onClick={MorethanFiftyCheck}
            >
              &gt; 15 Seats{" "}
              <i className="fa fa-times-circle" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a
              className={"CheckBox " + budgetFilter}
              onClick={budgetFilterCheck}
            >
              Economy <i className="fa fa-times-circle" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a
              className={"CheckBox " + businessFilter}
              onClick={businessFilterCheck}
            >
              Business <i className="fa fa-times-circle" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a
              className={"CheckBox " + premiumFilter}
              onClick={premiumFilterCheck}
            >
              Premium <i className="fa fa-times-circle" aria-hidden="true"></i>
            </a>
          </li>
        </ul>
      </div>
    )
  }

  const MeetingSpaceFilter = () => {
    useEffect(() => {
      setspaceSize(document.querySelectorAll(".listingCardPadding").length)
    })

    return (
      <div className="meetingSpaceFilter">
        <div>
          {count - spaceSize <= 1 ? (
            ""
          ) : (
            <a
              style={{
                color: "#4f9fc8",
                padding: "2px 5px",
                float: "right",
                fontSize: "16px",
                fontWeight: "bold",
              }}
              className="DesktopOnly"
              onClick={() => window.location.reload(true)}
            >
              CLEAR
            </a>
          )}
          <span style={{ fontSize: "18px" }}>Filters</span>{" "}
        </div>{" "}
        <strong>Amenities</strong>
        <ul style={{ listStyle: "none" }}>
          <li>
            <input
              type="checkbox"
              id="projector"
              name="projector"
              value="projector"
              checked={projectorCheck}
              onChange={projectorCheckOnChange}
            />
            Projector / TV{" "}
          </li>
          <li>
            <input
              type="checkbox"
              id="whiteboard"
              name="whiteboard"
              value="whiteboard"
              checked={whiteboardCheck}
              onChange={whiteboardCheckOnChange}
            />
            Whiteboard
          </li>

          <li>
            <input
              type="checkbox"
              id="teacoffee"
              name="teacoffee"
              value="teacoffee"
              checked={CoffeeTea}
              onChange={CoffeeTeaonChange}
            />
            Tea / Coffee
          </li>
          {/* <li>
            <input
              type="checkbox"
              id="wifi"
              name="wifi"
              value="wifi"
              checked={WiFiBackupCheck}
              onChange={WiFiBackupCheckOnChange}
            />
            WiFi Backup
          </li> */}
          <li>
            <input
              type="checkbox"
              id="Cafeteria"
              name="Cafeteria"
              value="Cafeteria"
              checked={cafeteriaCheck}
              onChange={cafeteriaCheckOnChange}
            />
            Pantry / Cafeteria
          </li>
        </ul>
        <strong>Transport</strong>
        <ul style={{ listStyle: "none" }}>
          <li>
            <input
              type="checkbox"
              id="parking"
              name="parking"
              value="parking"
              checked={parkingCheck}
              onChange={parkingCheckOnChange}
            />
            Parking
          </li>
          <li>
            {" "}
            <input
              type="checkbox"
              id="MetroNearbyCheck"
              name="MetroNearbyCheck"
              value="MetroNearbyCheck"
              checked={MetroNearbyCheck}
              onChange={MetroNearbyCheckOnChange}
            />
            Metro Nearby
          </li>
        </ul>
        <strong>Price / hr Range</strong>
        <br></br>
        <ul style={{ listStyle: "none" }}>
          <li>
            <input
              type="radio"
              name="priceFilter"
              checked={optionValue === "option_1"}
              value="option_1"
              onChange={value => {
                setoptionValue("option_1")
                setminiValue(0)
                setmaxiValue(500)
              }}
            ></input>
            Less than ₹ 500{" "}
          </li>
          <li>
            <input
              type="radio"
              name="priceFilter"
              checked={optionValue === "option_2"}
              value="option_2"
              onChange={value => {
                setoptionValue("option_2")
                setminiValue(500)
                setmaxiValue(800)
              }}
            ></input>
            ₹ 500 to ₹ 800
          </li>
          <li>
            {" "}
            <input
              type="radio"
              name="priceFilter"
              checked={optionValue === "option_3"}
              value="option_3"
              onChange={value => {
                setoptionValue("option_3")
                setminiValue(800)
                setmaxiValue(1200)
              }}
            ></input>
            ₹ 800 to ₹ 1200{" "}
          </li>
          <li>
            {" "}
            <input
              type="radio"
              name="priceFilter"
              checked={optionValue === "option_4"}
              value="option_4"
              onChange={value => {
                setoptionValue("option_4")
                setminiValue(1200)
                setmaxiValue(10000)
              }}
            ></input>
            Over ₹ 1200
          </li>
        </ul>
        <strong>Purpose</strong>
        <ul style={{ listStyle: "none" }}>
          <li>
            <input
              type="checkbox"
              id="OnetoOneMeetings"
              name="OnetoOneMeetings"
              value="OnetoOneMeetings"
              checked={OnetoOneMeetings}
              onChange={OnetoOneMeetingsChange}
            />
            One to One Meetings
          </li>
          <li>
            <input
              type="checkbox"
              id="TeamMeetings"
              name="TeamMeetings"
              value="TeamMeetings"
              checked={TeamMeetings}
              onChange={TeamMeetingsChange}
            />
            Team Meetings
          </li>
          <li>
            <input
              type="checkbox"
              id="BoardMeetingsPresentations"
              name="BoardMeetingsPresentations"
              value="BoardMeetingsPresentations"
              checked={BoardMeetingsPresentations}
              onChange={BoardMeetingsPresentationsChange}
            />
            Board Meetings & Presentations
          </li>
          <li>
            <input
              type="checkbox"
              id="Interviews"
              name="Interviews"
              value="Interviews"
              checked={Interviews}
              onChange={InterviewsChange}
            />
            Interviews
          </li>
          <li>
            <input
              type="checkbox"
              id="WorkshopsEvents"
              name="WorkshopsEvents"
              value="WorkshopsEvents"
              checked={WorkshopsEvents}
              onChange={WorkshopsEventsChange}
            />
            Workshops & Events
          </li>
          <li>
            <input
              type="checkbox"
              id="Training"
              name="Training"
              value="Training"
              checked={Training}
              onChange={TrainingChange}
            />
            Training
          </li>
        </ul>
        <strong>Operation Hours</strong>
        <ul style={{ listStyle: "none" }}>
          <li>
            <input
              type="radio"
              name="operationTime"
              value="Open till 8 pm"
              checked={operationTime === "Open till 8 pm"}
              onChange={operationTimeChange}
            />
            Open till 8 pm
          </li>
          <li>
            <input
              type="radio"
              name="operationTime"
              value="Open till 10 pm"
              checked={operationTime === "Open till 10 pm"}
              onChange={operationTimeChange}
            />
            Open till 10 pm
          </li>
          <li>
            <input
              type="radio"
              name="operationTime"
              value="24 x 7"
              checked={operationTime === "24 x 7"}
              onChange={operationTimeChange}
            />
            24 x 7
          </li>
        </ul>
        <strong>Weekend Operation</strong>
        <ul style={{ listStyle: "none" }}>
          <li>
            <input
              type="checkbox"
              name="saturdayOperation"
              value="Open till 8 pm"
              checked={saturdayOperation}
              onChange={saturdayOperationChange}
            />
            Saturday
          </li>
          <li>
            <input
              type="checkbox"
              name="sundayOperation"
              value="Open till 8 pm"
              checked={sundayOperation}
              onChange={sundayOperationChange}
            />
            Sunday
          </li>
        </ul>
      </div>
    )
  }

  const lists = props.data.allListings.edges
  const LocalityList = props.data.allLocalities.edges
  const listscount = props.data.city.edges
  const count = props.data.counter.totalCount
  const filterdata = props.data.counter.edges
  const filterdatasorted = filterdata.sort(function (a, b) {
    return a.node.priceHr - b.node.priceHr
  })
  let lowrate
  if (filterdatasorted.length > 0) {
    lowrate = filterdatasorted[0].node.priceHr
  } else {
    lowrate = 0
  }
  if (lowrate === "") {
    lowrate = 299
  }
  const filterdataseatsorted = filterdata.sort(function (a, b) {
    return a.node.seat - b.node.seat
  })
  const filterdataseatsortedlength = filterdataseatsorted.length
  let lowseat, highseat
  if (filterdatasorted.length > 0) {
    lowseat = filterdataseatsorted[0].node.seat
    highseat = filterdataseatsorted[filterdataseatsortedlength - 1].node.seat
  } else {
    lowseat = 0
    highseat = 0
  }

  let textnew
  if (lowseat !== highseat) {
    textnew = lowseat + " Seaters to " + highseat + " Seaters"
  } else {
    textnew = lowseat + " Seaters"
  }
  useEffect(() => {
    if (lists.length <= 0) {
      navigate("/meeting-spaces/" + cityslug + "/all")
    }
  }, [])
  const title =
    "Business Meeting Rooms in " +
    locality +
    ", " +
    city +
    " | Meeting Spaces in near " +
    locality +
    ", " +
    city +
    " | GoFloaters"
  const description =
    "On-demand meeting rooms in " +
    locality +
    ", " +
    city +
    " just a click away. Discover and book business meeting rooms, boardrooms, and conference spaces that help being hybrid, productive and great for collaborations."
  const counterValue = Number(count) > 1 ? " Spaces" : " Space"
  const counterValuesmall = Number(count) > 1 ? " spaces" : " space"
  const dynamictitle =
    count +
    " Best Meeting" +
    counterValue +
    " in " +
    locality +
    ", " +
    city +
    " starting at " +
    lowrate +
    "/hr - GoFloaters"
  const dynamicdescription =
    count +
    " fully equipped meeting" +
    counterValuesmall +
    " from " +
    textnew +
    " available in " +
    locality +
    ", " +
    city +
    " at rates starting " +
    lowrate +
    "/hr. Book instantly."
  const changeFilter = filterText => {
    setmobileFilterSecondary(false)
    setfilterStyle(filterText)
    if (filterText === "PriceLow") {
      lists.sort(function (a, b) {
        if (Number(a.node.priceHr) < Number(b.node.priceHr)) {
          return -1
        }
      })
      lists.sort(function (a, b) {
        if (Number(a.node.priceDay) < Number(b.node.priceDay)) {
          return -1
        }
      })
    }
    if (filterText === "PriceHigh") {
      lists.sort(function (a, b) {
        if (Number(a.node.priceDay) > Number(b.node.priceDay)) {
          return -1
        }
      })
      lists.sort(function (a, b) {
        if (Number(a.node.priceHr) > Number(b.node.priceHr)) {
          return -1
        }
      })
    }
    if (filterText === "User Rating") {
      lists.sort(function (a, b) {
        if (Number(a.node.Rating) > Number(b.node.Rating)) {
          return -1
        }
      })
    }
    if (filterText === "Nearest") {
      lists.sort(function (a, b) {
        if (Number(a.node.pincode) > Number(b.node.pincode)) {
          return -1
        }
      })
    }
    if (filterText === "Popular") {
      lists.sort(function (a, b) {
        if (Number(a.node.bookingcount) > Number(b.node.bookingcount)) {
          return -1
        }
      })
    }
  }
  lists.sort(function (a, b) {
    if (Number(a.node.online) > Number(b.node.online)) {
      return -1
    }
  })
  return (
    <div>
      <SEOHeader
        title={
          city === "Bengaluru" || city === "Chennai" ? dynamictitle : title
        }
        description={
          city === "Bengaluru" || city === "Chennai"
            ? dynamicdescription
            : description
        }
        socialURL={
          "https://assets.gofloaters.com/socialimage/meeting-rooms-in-" +
          cityslug +
          ".jpg"
        }
        pinterest="true"
      ></SEOHeader>
      <Layout>
        <div
          style={{
            position: "fixed",
            bottom: mobileFilterSecondary ? "0px" : "-100px",
            zIndex: "10",
            width: "100%",
            opacity: mobileFilterSecondary ? "1" : "0",
            background: "#fdfdfd",
            transition: "0.5s",
            overflow: "scroll",
            overflowX: "hidden",
            boxShadow: "0px -16px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div
            style={{ padding: "5px 15px", borderBottom: "1px solid #d6d6d6" }}
          >
            <p style={{ fontSize: "18px", fontWeight: "bold" }}>Sort</p>
          </div>

          <div className="col-md-12">
            <div
              className={
                filterStyle === "Popular" ? "sortingCard active" : "sortingCard"
              }
              onClick={() => {
                changeFilter("Popular")
              }}
            >
              <p>
                <b>Popular:</b> Frequently booked
              </p>
            </div>
          </div>

          <div className="col-md-12">
            <div
              className={
                filterStyle === "User Rating"
                  ? "sortingCard active"
                  : "sortingCard"
              }
              onClick={() => {
                changeFilter("User Rating")
              }}
            >
              <p>
                <b>User Rating:</b> Highest first
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div
              className={
                filterStyle === "PriceLow"
                  ? "sortingCard active"
                  : "sortingCard"
              }
              onClick={() => {
                changeFilter("PriceLow")
              }}
            >
              <p>
                <b>Price:</b> Lowest first
              </p>
            </div>
          </div>
          <div className="col-md-2">
            <div
              className={
                filterStyle === "PriceHigh"
                  ? "sortingCard active"
                  : "sortingCard"
              }
              onClick={() => {
                changeFilter("PriceHigh")
              }}
            >
              <p>
                <b>Price:</b> Highest first
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "fixed",
            top: "0",
            right: mobileFilter ? "0px" : "-200px",

            zIndex: "10",
            width: mobileFilter ? "100%" : "0px",
            // opacity: mobileFilter ? "1" : "0",
            height: "100vh",
            background: "#f8f8f8",
            transition: "0.5s",
            overflow: "scroll",
            overflowX: "hidden",
          }}
        >
          <div
            style={{
              position: "fixed",
              top: "0",
              width: "100%",
              padding: "15px",
              background: "#ffffff",
              zIndex: "12",
            }}
          >
            <span
              onClick={() => {
                setmobileFilter(!mobileFilter)
              }}
              style={{
                textAlign: "center",
                float: "right",
                background: "#303030",
                color: "#fff",
                width: "30px",
                height: "30px",
                alignItems: "center",
                borderRadius: "50%",
                fontSize: "19px",
                top: "30px",
              }}
            >
              X
            </span>{" "}
            Filter
            {count - spaceSize <= 1 ? (
              ""
            ) : (
              <a
                style={{
                  color: "#4f9fc8",
                  padding: "2px 5px",
                  float: "right",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
                onClick={() => window.location.reload(true)}
              >
                CLEAR
              </a>
            )}
          </div>
          <br />

          <br />

          <div style={{ padding: "20px" }}>
            <MeetingSpaceFilter />
          </div>
          <br />

          <div
            style={{
              position: "fixed",
              bottom: "0",
              width: "100%",
              padding: "15px",
              background: "#ffffff !important",
              zIndex: "11",
            }}
          >
            <div
              onClick={() => {
                setmobileFilter(!mobileFilter)
              }}
              style={{
                background: "#2798B5",
                color: "#fff",
                padding: "10px 20px",
                borderRadius: "5px",
                cursor: "default",
                width: "100%",
                textAlign: "center",
              }}
            >
              Apply Filter
            </div>
          </div>

          <br></br>
          <br></br>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12"></div>
            <div className="col-md-2 DesktopOnly">
              <MeetingSpaceFilter />
            </div>
            <div className="col-md-10">
              {" "}
              <div className="container">
                <br></br>
                <h1>
                  Meeting spaces in {locality}, {city}{" "}
                </h1>
                <p style={{ fontSize: "13px" }}>
                  If you are looking for meeting spaces or conference rooms in{" "}
                  {locality}, {city} then you have come to the right page. We
                  have meeting spaces in {locality}, {city} starting from Rs 250
                  / hr.{" "}
                </p>
                <div className="row ">
                  {/* <div className="col-md-1 col-1 text-center noPaddingM">
                    <a
                      onClick={() => {
                        document.getElementById(
                          "popularscrollmenu"
                        ).scrollLeft -= 100
                      }}
                      className="arrowButton"
                    >
                      <i className="fa  fa-chevron-left"></i>
                    </a>
                  </div> */}
                  <div className="col-md-12 col-12">
                    <div class="popularscrollmenu">
                      <Link
                        to={"/meeting-spaces/" + cityslug + "/all"}
                        className="popularlocalityLink"
                      >
                        All
                      </Link>
                      {LocalityList.map(local => {
                        const localitynew = listscount.filter(
                          list =>
                            list.node.spaceAddress === local.node.localityName
                        )
                        if (localitynew.length > 0) {
                          return (
                            <Link
                              to={
                                "/meeting-spaces/" +
                                cityslug +
                                "/" +
                                local.node.localitySlug
                              }
                              className={
                                local.node.localityName === locality
                                  ? "popularlocalityLink active centerAligner "
                                  : "popularlocalityLink centerAligner"
                              }
                            >
                              {local.node.localityName}
                              {"  "}
                              <div className="countSlip centerAligner">
                                {localitynew.length}
                              </div>
                            </Link>
                          )
                        }
                      })}
                    </div>
                  </div>
                  {/* <div className="col-md-1 col-1 text-center noPaddingM">
                    <a
                      onClick={() => {
                        document.getElementById(
                          "popularscrollmenu"
                        ).scrollLeft += 100
                      }}
                      className="arrowButton"
                    >
                      <i className="fa  fa-chevron-right"></i>
                    </a>
                  </div> */}
                </div>

                <div className="row" style={{ paddingBottom: "10px" }}>
                  <div className="col-md-12">
                    <OfficeFilter></OfficeFilter>
                  </div>
                </div>
                <div className="DesktopOnly">
                  <div className="row alignersorting sortingCardList">
                    <div className="col-md-2">
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: "#2193b0",
                        }}
                      >
                        SORT BY:
                      </p>
                    </div>
                    <div className="col-md-2">
                      <div
                        className={
                          filterStyle === "Popular"
                            ? "sortingCard active"
                            : "sortingCard"
                        }
                        onClick={() => {
                          changeFilter("Popular")
                        }}
                      >
                        <p>Popular</p>
                        <span>Frequently booked</span>
                      </div>
                    </div>

                    {/* <div className="col-md-2">
                      <div
                        className={
                          filterStyle === "Nearest"
                            ? "sortingCard active"
                            : "sortingCard"
                        }
                        onClick={() => {
                          changeFilter("Nearest")
                        }}
                      >
                        <p>Nearest</p>
                        <span>Closest to you</span>
                      </div>
                    </div> */}
                    <div className="col-md-2">
                      <div
                        className={
                          filterStyle === "User Rating"
                            ? "sortingCard active"
                            : "sortingCard"
                        }
                        onClick={() => {
                          changeFilter("User Rating")
                        }}
                      >
                        <p>User Rating</p>
                        <span>Highest first</span>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div
                        className={
                          filterStyle === "PriceLow"
                            ? "sortingCard active"
                            : "sortingCard"
                        }
                        onClick={() => {
                          changeFilter("PriceLow")
                        }}
                      >
                        <p>Price</p>
                        <span>Lowest first</span>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div
                        className={
                          filterStyle === "PriceHigh"
                            ? "sortingCard active"
                            : "sortingCard"
                        }
                        onClick={() => {
                          changeFilter("PriceHigh")
                        }}
                      >
                        <p>Price</p>
                        <span>Highest first</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row alignersorting">
                  <div className="MobileOnly ">
                    <span
                      onClick={() => {
                        setmobileFilter(!mobileFilter)
                      }}
                      className="sortNowButton"
                    >
                      <i
                        className="fa fa-filter"
                        style={{ paddingRight: "10px" }}
                      ></i>{" "}
                      Filter
                    </span>
                  </div>{" "}
                  <div className="MobileOnly ">
                    <span
                      onClick={() => {
                        setmobileFilterSecondary(!mobileFilterSecondary)
                      }}
                      className="sortNowButton"
                    >
                      <i
                        className="fa fa-sort-amount-asc"
                        style={{ paddingRight: "10px" }}
                      ></i>{" "}
                      Sort
                    </span>
                  </div>
                </div>
                <div className="col-md-12 col-12">
                  <p style={{ padding: "15px 0 0", color: "#7a7a7a" }}>
                    {spaceSize} Spaces match your filter{" "}
                  </p>
                </div>

                <div className="row">
                  {lists.map((list, i) => {
                    const listData = list.node
                    let count = 0
                    if (
                      listData.Facility.search(
                        PowerBackupCheck ? "Power Backup" : " "
                      ) > 1 &&
                      listData.Facility.search(
                        parkingCheck ? "Free Parking" : " "
                      ) > 1 &&
                      (listData.Facility.search(
                        projectorCheck ? "Projector" : " "
                      ) > 1 ||
                        listData.Facility.search(
                          projectorCheck ? "Television" : " "
                        ) > 1) &&
                      listData.Facility.search(
                        whiteboardCheck ? "Whiteboard" : " "
                      ) > 1 &&
                      listData.Facility.search(
                        cafeteriaCheck ? "Pantry" : " "
                      ) > 1 &&
                      listData.Facility.search(
                        powerBackup ? "Power Backup" : " "
                      ) > 1 &&
                      listData.Facility.search(
                        WiFiBackupCheck ? "WiFi Backup" : " "
                      ) > 1 &&
                      listData.Facility.search(CoffeeTea ? "Coffee/Tea" : " ") >
                        1 &&
                      listData.Facility.search(
                        MetroNearbyCheck ? "Metro Nearby" : " "
                      ) > 1 &&
                      ((listData.Facility.search(fiveseatquery) > 1 &&
                        listData.Facility.search(sixtofifteenquery) > 1 &&
                        listData.Facility.search(morethanfiftyquery) > 1) ||
                        listData.Facility.search(fivefiftyquery) > 1) &&
                      listData.Facility.search(
                        OnetoOneMeetings ? "One to One Meetings" : " "
                      ) > 1 &&
                      listData.Facility.search(
                        TeamMeetings ? "Team Meetings" : " "
                      ) > 1 &&
                      listData.Facility.search(
                        BoardMeetingsPresentations
                          ? "Board Meetings & Presentations"
                          : " "
                      ) > 1 &&
                      listData.Facility.search(budgetFilter ? "Budget" : " ") >
                        1 &&
                      listData.Facility.search(
                        businessFilter ? "Business" : " "
                      ) > 1 &&
                      listData.Facility.search(
                        premiumFilter ? "Premium" : " "
                      ) > 1 &&
                      listData.Facility.search(
                        Interviews ? "Interviews" : " "
                      ) > 1 &&
                      listData.Facility.search(
                        WorkshopsEvents ? "Workshops & Events" : " "
                      ) > 1 &&
                      listData.Facility.search(Training ? "Training" : " ") >
                        1 &&
                      listData.Facility.search(operationFilter) > 1 &&
                      listData.Facility.search(
                        saturdayOperation ? "Saturday Operation" : " "
                      ) > 1 &&
                      listData.Facility.search(
                        sundayOperation ? "Sunday Operation" : " "
                      ) > 1 &&
                      listData.Facility.search(Training ? "Training" : " ") >
                        1 &&
                      Number(listData.priceHr) > Number(miniValue) &&
                      Number(listData.priceHr) <= Number(maxiValue)
                    ) {
                      return (
                        <NewUIListingCard
                          key={listData.spaceId}
                          spacetype={listData.spaceType}
                          listingImg={listData.spaceImage}
                          title={listData.spaceTitle}
                          gftitle={listData.spaceGFName}
                          spaceDesc={listData.spaceDesc}
                          spaceAddress={listData.spaceAddress}
                          priceHr={listData.priceHr}
                          priceDay={listData.priceDay}
                          priceMonth={listData.priceMonth}
                          monthPassAvailable={listData.monthPassAvailable}
                          dayPassAvailable={listData.dayPassAvailable}
                          hourPassAvailable={listData.hourPassAvailable}
                          spaceId={listData.spaceId}
                          officeSpaceType={listData.officeSpaceType}
                          spaceDisplayName={listData.spaceDisplayName}
                          OriginalName={listData.OriginalName}
                          Facility={listData.Facility}
                          Slug={"/meeting-space/" + listData.slug}
                          hasCovidSafeBadge={listData.hasCovidSafeBadge}
                          online={listData.online}
                          Rating={listData.Rating}
                        ></NewUIListingCard>
                      )
                    }
                  })}
                </div>
                <br></br>
                <MeetingLocalityContent city={city} locality={locality} />
              </div>{" "}
            </div>
          </div>
        </div>
        <br></br>
        <br></br>{" "}
      </Layout>
    </div>
  )
}
export default MeetingSpacesLocality
export const query = graphql`
  query MeetingSpacesLocality($city: String!, $locality: String!) {
    city: allListings(
      filter: { spaceType: { eq: "Conference Room" }, spaceCity: { eq: $city } }
      sort: { fields: online, order: DESC }
    ) {
      totalCount
      edges {
        node {
          OriginalName
          spaceAddress
          dayPassAvailable
        }
      }
    }
    allListings(
      filter: {
        spaceType: { eq: "Conference Room" }
        spaceCity: { eq: $city }
        spaceAddress: { eq: $locality }
        slug: { ne: "" }
      }
      sort: { fields: bookingcount, order: DESC }
    ) {
      totalCount
      edges {
        node {
          monthPassAvailable
          dayPassAvailable
          hourPassAvailable
          officeSpaceType
          purposesList
          spaceAddress
          spaceGFName
          OriginalName
          spaceCity
          spaceId
          spaceImage
          spaceTitle
          spaceDesc
          spaceType
          subType
          priceDay
          priceHr
          priceMonth
          spaceDisplayName
          Facility
          slug
          hasCovidSafeBadge
          online
          Rating
          pincode
          bookingcount
          sunday {
            from
            holiday
            to
            useNow
          }
          saturday {
            from
            holiday
            to
            useNow
          }
        }
      }
    }

    counter: allListings(
      filter: {
        spaceType: { eq: "Conference Room" }
        spaceCity: { eq: $city }
        spaceAddress: { eq: $locality }
      }
    ) {
      totalCount
      edges {
        node {
          monthPassAvailable
          dayPassAvailable
          hourPassAvailable
          officeSpaceType
          purposesList
          spaceAddress
          spaceGFName
          OriginalName
          spaceCity
          spaceDesc
          spaceId
          spaceImage
          spaceTitle
          spaceType
          subType
          priceDay
          priceHr
          priceMonth
          spaceDisplayName
          Facility
          slug
          hasCovidSafeBadge
          seat
          online
          bookingcount
        }
      }
    }
    allLocalities(
      filter: { cityName: { eq: $city } }
      sort: { fields: localityName }
    ) {
      edges {
        node {
          cityName
          cityPriority
          cityslug
          displayCity
          id
          localitySort
          localitySlug
          localityName
        }
      }
    }
  }
`
